import { useEffect, useRef } from 'react';

var useFocus = function useFocus(itemRef, _ref) {
  var shouldFocus = _ref.shouldFocus,
      _ref$returnFocusOnLea = _ref.returnFocusOnLeave,
      returnFocusOnLeave = _ref$returnFocusOnLea === void 0 ? false : _ref$returnFocusOnLea;
  var prevFocusedElRef = useRef();
  useEffect(function () {
    if (shouldFocus && itemRef.current) {
      // eslint-disable-next-line fp/no-mutation
      prevFocusedElRef.current = typeof document !== "undefined" && document.activeElement;
      itemRef.current.focus();
    }

    return function () {
      var _ref2, _ref2$focus;

      return returnFocusOnLeave && ((_ref2 = prevFocusedElRef === null || prevFocusedElRef === void 0 ? void 0 : prevFocusedElRef.current) === null || _ref2 === void 0 ? void 0 : (_ref2$focus = _ref2.focus) === null || _ref2$focus === void 0 ? void 0 : _ref2$focus.call(_ref2));
    };
  }, [shouldFocus, itemRef, prevFocusedElRef, returnFocusOnLeave]);
};

export default useFocus;