import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n      border-radius: 50%;\n    "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n      color: ", ";\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  align-items: center;\n  background: #ffffff;\n  border: 0;\n  ", "\n  display: inline-flex;\n  ", "\n  height: ", ";\n  justify-content: center;\n  min-width: ", ";\n  margin: 0 3px;\n  outline: 0;\n  padding: 0 6px;\n  text-align: center;\n  cursor: pointer;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled, { css } from 'styled-components';
import { legacySwappableCss } from '../../theme';
var StyledButton = styled.button(_templateObject(), legacySwappableCss(css(_templateObject2(), function (p) {
  return p.theme.grey2;
}), css(_templateObject3())), function (p) {
  return p.theme.fontCss('body');
}, function (p) {
  return p.theme.spacing(4);
}, function (p) {
  return p.theme.spacing(4);
});
export default StyledButton;