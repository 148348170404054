import styled from 'styled-components'

export default styled.button`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  border-radius: 5px;
  font-family: 'Rajdhani', sans-serif;
  line-height: 1.4;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.15s ease-out;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: 0;
  }
`
