"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _default = function _default(scope) {
  var query = "@media ".concat(scope);

  var rule = function rule(ruleKey) {
    var maxWidths = function maxWidths(widths) {
      return Object.entries(widths).map(function (_ref) {
        var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
            width = _ref2[0],
            ruleValue = _ref2[1];

        return "\n          ".concat(query, " and (max-width: ").concat(width, ") {\n            ").concat(ruleKey, ": ").concat(ruleValue, ";\n          }\n        ");
      }).join('\n');
    };

    return {
      maxWidths: maxWidths
    };
  };

  var maxWidths = function maxWidths(widths) {
    return Object.entries(widths).map(function (_ref3) {
      var _ref4 = (0, _slicedToArray2["default"])(_ref3, 2),
          width = _ref4[0],
          css = _ref4[1];

      return "\n      ".concat(query, " and (max-width: ").concat(width, ") {\n        ").concat(css, "\n      }\n    ");
    }).join('\n');
  };

  return {
    rule: rule,
    maxWidths: maxWidths
  };
};

exports["default"] = _default;