import { useStaticQuery, graphql } from 'gatsby'

const useMetadata = () =>
  useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)?.site?.siteMetadata || {}

export default useMetadata
