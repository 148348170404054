import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useCartItemList } from '../../cart'

const H3 = styled.h3`
  font-weight: normal;
`

const ExpireWarning = styled.h4`
  color: darkred;
`

const CheckoutSuccess = ({
  response: {
    items = [],
    person: { email },
  },
}) => {
  const sendCartItemListAction = useCartItemList(s => s.action)
  const itemsWithDownloads = items.filter(({ downloads }) => downloads?.length)

  useEffect(() => {
    sendCartItemListAction({ action: 'empty' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <H3>Order placed successfully!!</H3>
      {itemsWithDownloads.length > 0 && (
        <>
          <h3>You have purchased the following items with downloads:</h3>
          <ul>
            {itemsWithDownloads.map(({ label, downloads }) => (
              <li>
                {label}
                {downloads.length &&
                  downloads.map(({ url, key }) => (
                    <ul>
                      - Download{' '}
                      <a href={url} target="_blank" rel="noreferrer">
                        {key}
                      </a>
                    </ul>
                  ))}
              </li>
            ))}
          </ul>
          <ExpireWarning>
            <b>IMPORTANT:</b> The links above will expire in 20 minutes, so be
            sure to download your files right away. If they expire and you need
            new links,{' '}
            <a href={`/downloads/?email=${email}`}>
              click here to have new links sent to your e-mail account
            </a>
            .
          </ExpireWarning>
        </>
      )}
    </>
  )
}

export default CheckoutSuccess
