import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  align-items: 0;\n  display: flex;\n  list-style: none;\n  margin: ", ";\n  padding: 0;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from 'styled-components';
export var StyledPaginationList = styled.ul(_templateObject(), function (p) {
  return p.theme.spacing(0.5);
});