import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-top: auto;\n  text-align: right;\n  padding-top: 24px;\n  width: 100%;\n\n  button {\n    margin: 0px;\n  }\n\n  button + button {\n    margin-left: 16px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from 'styled-components';
var ModalActions = styled.div(_templateObject());
export default ModalActions;