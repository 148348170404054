import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useRef, createRef } from 'react';
import { useClickAway, KEYCODES } from '../../shared';

var useDropdownList = function useDropdownList(_ref) {
  var _ref$items = _ref.items,
      items = _ref$items === void 0 ? [] : _ref$items,
      _ref$onSelect = _ref.onSelect,
      onSelect = _ref$onSelect === void 0 ? function (opt) {
    return opt;
  } : _ref$onSelect,
      value = _ref.value,
      _ref$anchorRef = _ref.anchorRef,
      anchorRef = _ref$anchorRef === void 0 ? createRef() : _ref$anchorRef;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      dropdownShown = _useState2[0],
      setDropdownShown = _useState2[1];

  var _useState3 = useState(0),
      _useState4 = _slicedToArray(_useState3, 2),
      activeOption = _useState4[0],
      setActiveOption = _useState4[1];

  var _useState5 = useState(),
      _useState6 = _slicedToArray(_useState5, 2),
      focusedOption = _useState6[0],
      setFocusedOption = _useState6[1];

  var setActiveFocusedOption = function setActiveFocusedOption(i) {
    setActiveOption(i);
    setFocusedOption(i);
  };

  var reset = function reset() {
    setActiveOption(0);
    setFocusedOption(undefined);
    if (dropdownShown) setDropdownShown(false);
  };

  var handleSelect = function handleSelect(i) {
    reset();
    onSelect(items[i]);
  };

  var setSelected = function setSelected(i) {
    if (dropdownShown) {
      setActiveFocusedOption(i);
    }

    onSelect(items[i]);
  };

  var selectActiveOption = function selectActiveOption() {
    return dropdownShown && handleSelect(activeOption);
  };

  var handleKeyDown = function handleKeyDown(e) {
    switch (e.keyCode) {
      case KEYCODES.ESC:
        e.preventDefault();
        reset();
        break;

      case KEYCODES.ENTER:
        e.preventDefault();
        selectActiveOption();
        break;

      case KEYCODES.SPACE:
        e.preventDefault();
        selectActiveOption();
        if (!dropdownShown) setDropdownShown(true);
        break;

      case KEYCODES.UP_ARROW:
        e.preventDefault();
        if (!items.length) return;
        if (!dropdownShown) setDropdownShown(true);else {
          if (activeOption === 0) return;
          setActiveFocusedOption(function (n) {
            return n - 1;
          });
        }
        break;

      case KEYCODES.DOWN_ARROW:
        e.preventDefault();
        if (!items.length) return;
        if (!dropdownShown) setDropdownShown(true);else {
          if (activeOption === items.length - 1) return;
          setActiveFocusedOption(function () {
            var n = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
            return n + 1;
          });
        }
        break;

      case KEYCODES.HOME:
        e.preventDefault();
        if (items.length && dropdownShown && activeOption !== 0) setActiveFocusedOption(0);
        break;

      case KEYCODES.END:
        e.preventDefault();
        if (items.length && dropdownShown && activeOption !== items.length - 1) setActiveFocusedOption(items.length - 1);
        break;

      default:
    }
  };

  var getItemProps = function getItemProps(i) {
    return {
      onClick: function onClick() {
        handleSelect(i);
      },
      active: activeOption === i,
      onMouseOver: function onMouseOver() {
        return setActiveOption(i);
      },
      selected: value === items[i].value,
      value: items[i].value,
      isFocused: focusedOption === i
    };
  };

  var listRef = useRef(null);
  useClickAway(listRef, function (e) {
    var anchorEl = anchorRef.current;
    var didClickInAnchor = anchorEl && (anchorEl === e.target || anchorEl.contains(e.target));
    if (!didClickInAnchor) setDropdownShown(false);
  });
  var listProps = {
    ref: listRef
  };
  return {
    onKeyDown: handleKeyDown,
    dropdownShown: items && items.length > 0 && dropdownShown,
    setDropdownShown: setDropdownShown,
    getItemProps: getItemProps,
    listProps: listProps,
    setSelected: setSelected,
    activeOption: activeOption,
    setActiveOption: setActiveFocusedOption,
    setActiveOptionNoFocus: setActiveOption,
    focusedOption: focusedOption,
    selectActiveOption: selectActiveOption
  };
};

export default useDropdownList;