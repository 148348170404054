"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ShippingRate", {
  enumerable: true,
  get: function get() {
    return _ShippingRate["default"];
  }
});
Object.defineProperty(exports, "Person", {
  enumerable: true,
  get: function get() {
    return _Person["default"];
  }
});
Object.defineProperty(exports, "PersonAddress", {
  enumerable: true,
  get: function get() {
    return _PersonAddress["default"];
  }
});
Object.defineProperty(exports, "PersonAddressGroup", {
  enumerable: true,
  get: function get() {
    return _PersonAddressGroup["default"];
  }
});
Object.defineProperty(exports, "Purchase", {
  enumerable: true,
  get: function get() {
    return _Purchase["default"];
  }
});
Object.defineProperty(exports, "PurchaseLineItem", {
  enumerable: true,
  get: function get() {
    return _PurchaseLineItem["default"];
  }
});
Object.defineProperty(exports, "Download", {
  enumerable: true,
  get: function get() {
    return _Download["default"];
  }
});
Object.defineProperty(exports, "PersonName", {
  enumerable: true,
  get: function get() {
    return _PersonName["default"];
  }
});
exports["default"] = void 0;

var _graphql = require("@foundation-lib/graphql");

var _ShippingRate = _interopRequireDefault(require("./models/ShippingRate"));

var _Person = _interopRequireDefault(require("./models/Person"));

var _PersonAddress = _interopRequireDefault(require("./models/PersonAddress"));

var _PersonAddressGroup = _interopRequireDefault(require("./models/PersonAddressGroup"));

var _Purchase = _interopRequireDefault(require("./models/Purchase"));

var _PurchaseLineItem = _interopRequireDefault(require("./models/PurchaseLineItem"));

var _Download = _interopRequireDefault(require("./models/Download"));

var _PersonName = _interopRequireDefault(require("./models/PersonName"));

var schema = "\n  scalar NonEmptyString\n  scalar NonZeroFloat\n  scalar Currency\n\n  ".concat((0, _graphql.makeMultipleSchemas)(_graphql.makeTypeSchema, [_ShippingRate["default"], _Person["default"], _PersonName["default"], _PersonAddress["default"], _PersonAddressGroup["default"], _Purchase["default"], _PurchaseLineItem["default"], _Download["default"]]), "\n\n  ").concat((0, _graphql.makeMultipleSchemas)(_graphql.makeInputSchema, [_Person["default"], _PersonName["default"], _PersonAddress["default"], _PersonAddressGroup["default"], _PurchaseLineItem["default"]]), "\n\n  type GetDownloadsSummary {\n    itemCount: Int\n  }\n\n  type Query {\n    ").concat((0, _graphql.makeQuerySchema)(_ShippingRate["default"]), "\n    \n    downloads(\n      email: String!\n    ): GetDownloadsSummary\n  }\n\n  type Mutation {\n    ").concat((0, _graphql.makeMultipleSchemas)(_graphql.makeMutationSchema, [_Purchase["default"]]), "\n  }  \n"); // console.log(schema)

var _default = "\n  ".concat(schema, "\n");

exports["default"] = _default;