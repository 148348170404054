import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n  font-weight: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n  font-weight: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n  font-weight: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from 'styled-components';
export var DisplayExtraLarge = styled.h1(_templateObject(), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('displayExtraLarge');
});
export var DisplayLarge = styled.h2(_templateObject2(), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('displayLarge');
});
export var DisplayMedium = styled.h3(_templateObject3(), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('displayMedium');
}, function (p) {
  return p.bold ? p.theme.fontWeightBold : p.theme.fontWeightNormal;
});
export var DisplaySmall = styled.h4(_templateObject4(), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('displaySmall');
}, function (p) {
  return p.bold ? p.theme.fontWeightBold : p.theme.fontWeightNormal;
});
export var Heading = styled.h5(_templateObject5(), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('heading');
});
export var HeadingSmall = styled.h6(_templateObject6(), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('headingSmall');
}, function (p) {
  return p.bold ? p.theme.fontWeightBold : p.theme.fontWeightNormal;
});
export var SubHeading = styled.p(_templateObject7(), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('subHeading');
});
export var Body = styled.p(_templateObject8(), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('body');
});
export var Caption = styled.small(_templateObject9(), function (p) {
  return p.brand ? p.theme[p.theme.brand] : p.theme.text;
}, function (p) {
  return p.theme.fontCss('caption');
});