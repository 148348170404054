import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 20px;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n      margin: ", ";\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n      margin: ", ";\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  color: ", ";\n  font-size: 14px;\n  font-family: Lato-Regular, sans-serif;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: rgb(51, 51, 51);\n  font-size: ", ";\n  font-family: Lato-Bold, sans-serif;\n  font-weight: bold;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  margin: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled, { css } from 'styled-components';
import { legacySwappableCss } from '../../../theme';
export var GroupContainer = styled.div(_templateObject(), function (p) {
  return p.theme.spacing(2);
});
export var GroupLabel = styled.span(_templateObject2(), function (p) {
  return p.theme.spacing(2);
});
export var HelperText = styled.div(_templateObject3(), legacySwappableCss(css(_templateObject4(), function (p) {
  return p.theme.spacing(0.5, 0, 0, 0);
}), css(_templateObject5(), function (p) {
  return p.theme.spacing(0.5, 0, 0, 2);
})), function (_ref) {
  var hasError = _ref.hasError,
      theme = _ref.theme;
  if (hasError) return theme.danger;
  return theme.grey3;
});
export var GroupHelperText = styled(HelperText)(_templateObject6());