import styled from 'styled-components'
import Button from '../Button'
import colors from '../../variables/colors'

export default styled(Button)`
  padding: 0.5rem 1rem;
  font-size: 22px;
  ${p =>
    p.inverted
      ? `
        background-color: ${colors.primary};
        color: ${colors.background};
        &:hover {
          background-color: ${colors.primaryHover};
        }
      `
      : `
        background-color: ${colors.background};
        color: ${colors.primary};
        &:hover {
          background-color: #dedbd4;
        }      
      `}
  &:focus {
    box-shadow: 0 0 0 0.1875rem rgba(242, 240, 236, 0.35);
  }
  &:active {
    background-color: #dedbd4;
    &:focus {
      box-shadow: 0 0 0 0.1875rem rgba(242, 240, 236, 0.35);
    }
  }
  @media only screen and (max-width: 800px) {
    padding: 0.4rem 0.9rem;
    font-size: 18px;
  }
  @media only screen and (max-width: 600px) {
    span {
      display: none;
    }
  }
`
