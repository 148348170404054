import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useContext } from 'react';
import GroupContext from '../components/GroupContext';

var useRadioGroupMember = function useRadioGroupMember(optionValue) {
  var radioGroup = useContext(GroupContext);

  if (radioGroup) {
    var disabled = radioGroup.disabled,
        name = radioGroup.name,
        onChange = radioGroup.onChange,
        value = radioGroup.value;
    return _objectSpread(_objectSpread(_objectSpread({}, value && {
      checked: optionValue === value
    }), onChange && {
      onChange: onChange
    }), {}, {
      name: name,
      disabled: disabled,
      value: optionValue
    });
  }

  return undefined;
};

export default useRadioGroupMember;