import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: ", ";\n  padding: 1px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from 'styled-components';
var MarginOffset = styled.div(_templateObject(), function (p) {
  return {
    none: '0px',
    medium: '-11px'
  }[p.margin || 'medium'];
});
export default MarginOffset;