import { gql } from 'apollo-boost'
import { makeGetListQuery } from '@foundation-lib/graphql'
import { ShippingRate } from '@foundations-of-grace-pub/graphql-schema'

export default gql`
  ${makeGetListQuery(ShippingRate)`
    serviceName
    serviceCode
    shipmentCost
    otherCost
  `}
`
