import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n      border: 1px solid ", ";\n      box-shadow: ", ";\n      :hover {\n        box-shadow: ", ";\n      }\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background: #ffffff;\n  ", "\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled, { css } from 'styled-components';
import Button from './Button';
import { legacySwappableCss } from '../../theme';
var StyledStepButton = styled(Button)(_templateObject(), legacySwappableCss('', css(_templateObject2(), function (p) {
  return p.theme.grey6;
}, function (p) {
  return p.theme.shadowSmallest;
}, function (p) {
  return p.theme.shadowSmall;
})), function (_ref) {
  var isHidden = _ref.isHidden;
  return isHidden && "\n    visibility: hidden;\n  ";
});
export default StyledStepButton;