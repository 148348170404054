import { gql } from 'apollo-boost'
import { makeCreateMutationQuery } from '@foundation-lib/graphql'
import { Purchase } from '@foundations-of-grace-pub/graphql-schema'

export default gql`
  ${makeCreateMutationQuery(Purchase)`
    id
    person {
      email
    }
    items {
      label
      type
      downloads {
        url
        key
      }
    }
  `}
`
