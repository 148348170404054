import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useWindowScroll } from 'react-use'
import { media } from '@foundation-lib/css'
import { Icon } from '@foundation-lib/ui'
import { faShoppingCart } from '@foundation-lib/ui/icons/solid'
import { useCartItemList } from '@foundations-of-grace-pub/checkout'
import HeaderButton from './HeaderButton'
import colors from '../../variables/colors'
import useTitleMetadata from '../../lib/hooks/useTitleMetadata'

const CartButton = styled(HeaderButton)`
  margin-left: 15px;
  ${p =>
    p.float &&
    ` 
      position: fixed;
      z-index: 1000;
      top: 20px;
      right: 20px;

      @media (max-width: 800px) {
        top: 15px;
        right: 15px;
      }
    `}
`

const BuyButton = styled(HeaderButton)``

const ButtonContainer = styled.div`
  @media (max-width: 420px) {
    & ${p => (p.cartHasItems ? BuyButton : CartButton)} {
      display: none;
    }
  }
`

const Header = styled.header`
  width: 100%;
  padding: 25px 0;
  background-color: ${colors.primary};
  @media only screen and (max-width: 1000px) {
    padding: 20px 0;
  }
  @media only screen and (max-width: 800px) {
    padding: 15px 0;
  }
`

const Name = styled(Link)`
  font-family: 'Rajdhani', sans-serif;
  font-size: 44px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: ${colors.secondary};
  ${media('only screen').rule('font-size').maxWidths({
    '1000px': '40px',
    '800px': '36px',
    '600px': '32px',
  })}
`

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 30px;
  @media only screen and (max-width: 1000px) {
    padding: 0 20px;
  }
  @media only screen and (max-width: 800px) {
    padding: 0 15px;
  }
`

export default () => {
  const { y } = useWindowScroll()
  const { title } = useTitleMetadata()
  const itemCount = useCartItemList(s => s.getItemCount())

  return (
    <Header>
      <Inner>
        <Name to="/">{title}</Name>
        <div />
        <ButtonContainer cartHasItems={itemCount > 0}>
          <BuyButton as={Link} to="/#buyTheBook">
            Buy<span> the book</span>
          </BuyButton>
          <CartButton as={Link} to="/cart" inverted float={y > 96}>
            <Icon icon={faShoppingCart} />
            <span> Cart</span> ({itemCount})
          </CartButton>
        </ButtonContainer>
      </Inner>
    </Header>
  )
}
