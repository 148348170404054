import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: ", ";\n  @media (max-width: 400px) {\n    width: 100%;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { css } from 'styled-components';
var inputWidth = css(_templateObject(), function (p) {
  return {
    small: '100px',
    medium: '275px',
    large: '325px',
    full: '100%'
  }[p.width || 'medium'];
});
export default inputWidth;