import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n      background-color: ", ";\n      color: ", ";\n    "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  background-color: ", ";\n  color: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled, { css } from 'styled-components';
import Button from './Button';
import { legacySwappableCss } from '../../theme'; // LEGACY theme

var legacyStyles = css(_templateObject(), function (p) {
  return p.theme.primary;
}, function (p) {
  return p.theme.grey2;
}); // END LEGACY theme

export var StyledCurrentPageButton = styled(Button)(_templateObject2(), legacySwappableCss(legacyStyles, css(_templateObject3(), function (p) {
  return p.theme.relativeBlack;
}, function (p) {
  return p.theme.relativeWhite;
})));
export default StyledCurrentPageButton;