import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  :hover {\n    text-decoration: underline;\n  }\n\n  disabled\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from 'styled-components';
import Button from './Button';
var StyledDestinationPageButton = styled(Button)(_templateObject());
export default StyledDestinationPageButton;