import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  && {\n    // Beat .markdown specifity\n    margin-top: 0;\n  }\n\n  &,\n  & * {\n    outline: none;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import styled from 'styled-components';
var StyledPaginationItem = styled.li(_templateObject());
export default StyledPaginationItem;