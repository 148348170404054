import { useEffect } from 'react';

var useInertOther = function useInertOther(ref) {
  useEffect(function () {
    if (typeof document !== "undefined") {
      document.body.childNodes.forEach(function (el) {
        var _ref, _ref$setAttribute;

        return !el.contains(ref.current) && ((_ref = el) === null || _ref === void 0 ? void 0 : (_ref$setAttribute = _ref.setAttribute) === null || _ref$setAttribute === void 0 ? void 0 : _ref$setAttribute.call(_ref, 'inert', 'true'));
      });
      return function () {
        return document.body.childNodes.forEach(function (el) {
          var _ref2, _ref2$removeAttribute;

          return (_ref2 = el) === null || _ref2 === void 0 ? void 0 : (_ref2$removeAttribute = _ref2.removeAttribute) === null || _ref2$removeAttribute === void 0 ? void 0 : _ref2$removeAttribute.call(_ref2, 'inert');
        });
      };
    }
  }, [ref]);
};

export default useInertOther;