import React from 'react'
import { Icon } from '@foundation-lib/ui'
import { faSpinner } from '@foundation-lib/ui/icons/solid'
import { isNumber } from 'lodash'
import { TableRow } from './parts'
import { DescriptionCell, PriceCell } from './StandardRow'

const ShippingRow = ({
  data,
  cantCompute,
  cantComputeLabel = 'Enter Address',
  isLoading,
  ...other
}) => (
  <TableRow
    {...other}
    data={{ ...data, qty: 1, label: 'Shipping' }}
    columns={[
      DescriptionCell,
      (() => {
        if (cantCompute)
          return () => (
            <span style={{ fontSize: '12px' }}>{cantComputeLabel}</span>
          )
        if (isLoading) return () => <Icon icon={faSpinner} rotation={90} />
        if (!isNumber(data?.price)) return () => 'Error'
        return PriceCell
      })(),
    ]}
  />
)

export default ShippingRow
